@use 'src/shared/styles/variables/mediaQuery';

.wrapper {
	display: grid;
	grid-template-columns: 1fr 3fr;
	gap: 1.5rem;

	& > div:first-child {
		width: auto;
		height: fit-content;
	}

	& > div:last-child {
		width: 100%;
		height: fit-content;
	}

	@media (width <= 1279px) {
		grid-template-columns: 1fr 2.5fr;
	}
}

.line_tabs {
	margin-bottom: 1.5rem;
}