.form_components {
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	max-height: calc(90svh - 11.25rem);

	// Не дает обрезать тень при overflow: auto
	margin: -10px;
	margin-bottom: 1rem;
	padding: 10px;
}

.category {
	width: 40%;
}

.buttons {
	display: flex;
	gap: 2rem;
	justify-content: center;
}