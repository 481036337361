@charset "UTF-8";

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  content: "";

  position: absolute;
  top: 6px;

  display: block;

  width: 9px;
  height: 9px;

  border-color: var(--color-secondary-1);
  border-style: solid;
  border-width: 2px 2px 0 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  position: absolute;
  width: 0;
  margin-left: -4px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  content: "";

  position: absolute;
  z-index: -1;
  left: -8px;

  box-sizing: content-box;
  width: 1px;
  height: 0;

  border: 8px solid transparent;
  border-width: 8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
  padding: 0;
  border: 0;
}

.react-datepicker {
  position: relative;

  display: inline-block;

  font-family: Lato, helvetica, arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;

  // color: #000;
  background: var(--color-bg-blocks);
  border-radius: 0.75rem;
  box-shadow: 0 0 2px rgb(121 133 164 / 40%), 0 0 8px rgb(121 133 164 / 10%);
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  right: 50px;
  left: auto;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  right: 42px;
  left: auto;
}

.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  right: auto;
  left: 42px;
}

.react-datepicker__header {
  position: relative;

  padding: 16px 0 8px;

  text-align: center;

  background-color: var(--color-bg-blocks);
  border-top-left-radius: 0.75rem;
}

.react-datepicker__header--time {
  //padding-bottom: 8px;
  //padding-left: 5px;
  //padding-right: 5px;
  padding: 0; // mod

  & > div {
    display: none; // mod
  }
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0.75rem;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.75rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}

.react-datepicker__current-month {
  margin-top: .5rem;
  margin-bottom: 1.25rem;

  &::first-letter {
    text-transform: uppercase;
  }
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-secondary-3);
}

.react-datepicker-time__header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-datepicker__navigation {
  cursor: pointer;

  position: absolute;
  z-index: 1;
  top: 2px;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2rem;
  height: 2rem;
  padding: 0;

  text-align: center;
  text-indent: -999em;

  background: none;
  border: none;
}

.react-datepicker__navigation--previous {
  top: .9rem;
  left: 0.125rem;
}

.react-datepicker__navigation--next {
  top: .9rem;
  right: 0.125rem;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;

  display: block;

  margin-right: auto;
  margin-left: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-previous::before {
  content: "";

  position: absolute;
  bottom: 50%;
  left: calc(50% - 6px);
  transform: rotate(135deg);

  display: block;

  width: 9px;
  height: 9px;

  border-color: var(--color-secondary-1);
  border-style: solid;
  border-width: 2px 2px 0 0;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation--years-upcoming::before {
  content: "";

  position: absolute;
  top: 50%;
  left: calc(50% - 6px);
  transform: rotate(-45deg);

  display: block;

  width: 9px;
  height: 9px;

  border-color: var(--color-secondary-1);
  border-style: solid;
  border-width: 2px 2px 0 0;
}

// TODO: navigation
.react-datepicker__navigation:hover *::before {
  border-color: var(--color-secondary-3);
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  width: 0;
  font-size: 20px;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next::before {
  left: -7px;
  transform: rotate(45deg);
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous::before {
  right: -7px;
  transform: rotate(225deg);
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}

.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0 .75rem .75rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 5rem;
  height: 1.6rem;
  margin: .1rem;
}

.react-datepicker__input-time-container {
  float: left;
  clear: both;

  width: 100%;
  margin: 5px 0 10px 15px;

  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  display: inline-block;
  margin-left: 5px;
}

.react-datepicker__time-container {
  float: right;
  width: 85px;
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container--with-today-button {
  position: absolute;
  top: 0;
  right: -87px;

  display: inline;

  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-radius: 0.75rem; // mod
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  overflow-x: hidden;

  width: 85px;
  margin: 0 auto;

  text-align: center;

  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  overflow-y: scroll;

  box-sizing: content-box;
  width: 100%;
  height: calc(195px + (1.7rem / 2));
  margin: 0;
  padding-right: 0;
  padding-left: 0;

  list-style: none;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
  border-radius: .75rem; // mod
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  font-weight: bold;
  color: white;
  background-color: var(--color-secondary-3);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: var(--color-secondary-3);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  display: inline-block;

  width: 1.7rem;
  margin: 0.166rem;

  line-height: 1.7rem;
  color: #ccc;
  text-align: center;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: #f0f0f0;
  border-radius: 0.3rem;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
  color: var(--color-secondary-3);
}

.react-datepicker__day {
  color: var(--color-grayscale-2);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: inline-block;

  // width: 1.53rem;
  // height: 1.47rem;

	width: 2.2rem;
  height: 2.2rem;
  margin: 0.1rem;

  line-height: 2.1;
  text-align: center;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  color: var(--color-black-1);
  background: var(--color-secondary-3);
  border-radius: 15px;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background: var(--color-secondary-3);
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  pointer-events: none;
  color: #ccc;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover {
	color: var(--color-black-1);
  background: var(--color-primary-1);
  border-radius: 100%;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  color: var(--color-black-1);
  background: var(--color-primary-1);
  border-radius: 15px;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  color: var(--color-primary-1);
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  color: #fff;
  background-color: var(--color-secondary-3);
  border-radius: 0.3rem;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: var(--color-secondary-3);
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: var(--error-dark);
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: var(--color-primary-1);
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  color: var(--color-black-1);
  background: var(--color-primary-1);
  border-radius: 100%;
}

.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  color: var(--color-black-1);
  background: var(--color-primary-1);
  border-radius: 15px;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
  background-color: var(--color-primary-1);
  border-radius: 100%;
}

.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: var(--color-primary-1);
  border-radius: 15px;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: var(--color-primary-1);
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: var(--color-secondary-3);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  color: #000;
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: var(--color-secondary-3);
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color:var(--color-primary-1);
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  position: relative;
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 0;
  right: -16px;
  transform: rotate(135deg);
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 25%;

  width: 50%;

  text-align: center;

  background-color: var(--color-bg-blocks);
  box-shadow: 0 0 2px rgb(121 133 164 / 40%), 0 0 8px rgb(121 133 164 / 10%);
  border-radius: 0.75rem;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  overflow-y: scroll;
  height: 150px;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  display: block;

  width: 100%;
  margin-right: auto;
  margin-left: auto;

  line-height: 20px;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  user-select: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  color: var(--color-primary-1);
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
  color: var(--color-primary-1);
}

.react-datepicker__close-icon {
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0;

  display: table-cell;

  height: 100%;
  padding: 0 6px 0 0;

  vertical-align: middle;

  background-color: transparent;
  border: 0;
  outline: 0;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  content: "×";

  display: table-cell;

  width: 16px;
  height: 16px;
  padding: 2px;

  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  vertical-align: middle;

  background-color: var(--color-secondary-3);
  border-radius: 50%;
}

.react-datepicker__today-button {
  cursor: pointer;

  clear: left;

  padding: 5px 0;

  font-weight: bold;
  text-align: center;

  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
}

.react-datepicker__portal {
  position: fixed;
  z-index: 2147483647;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: rgb(0 0 0 / 80%);
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (width <= 400px), (height <= 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1rem;
}

.react-datepicker__children-container {
  width: 13.8rem;
  height: auto;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
}

.react-datepicker__aria-live {
  position: absolute;

  overflow: hidden;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  clip-path: circle(0);
  border: 0;
}

.react-datepicker__day--outside-month {
  color: var(--color-black-3);
}
