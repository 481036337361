@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.card {
 @include layout.card;
 margin-bottom: 0.75rem;

 &:hover {
	cursor: pointer;
 }

	&:last-child {
		margin-bottom: 0;
	}

 & > * {
	margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0; 
		}
	}
}

.flex_wrapper {
	display: flex;
	justify-content: space-between;
}

.month_section {
	margin-bottom: 1.5rem;
	
	&:last-child {
		margin-bottom: 0;
	}

	&_title {
		align-items: center;
		margin-bottom: 0.75rem;
	}
}

.info {
	@include typography.font-headline-8;
	display: flex;
	gap: 1rem;

	margin-bottom: 0.5rem;

	&_date {
		color: var(--color-secondary-3);
	}
	
	&_status_active {
		color: var(--success-dark);
	}

	&_status_past {
		color: var(--color-black-3);
	}
}

.title {
	@include typography.font-headline-3;
}

.section_name {
	@include typography.font-body-3;
	color: var(--color-secondary-3);
}
