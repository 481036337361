@use "../../../../styles/variables/typography";

.container {
	@include typography.font-field-label;

	display: inline-block;
	height: fit-content;
	margin-bottom: .25rem;
	color: var(--color-secondary-3);
}

.required {
	color: var(--error-dark);
}
