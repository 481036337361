@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.container {
	@include layout.flex(row, 1.5rem, flex-start);

	& > div:first-child {
		flex-shrink: 0;
	}
}

.reply_container {
	margin-left: 5.25rem;
}

.content {
	width: 100%;
}

.header {
	@include layout.flex(row, 1rem, space-between);

	&__inner {
		@include layout.flex(row, 1rem, flex-start);
	}

	&__inner > span {
		@include typography.font-body-2;

		color: var(--color-secondary-3);
	}
}

.reply_user {
	color: var(--color-primary-1);
	cursor: pointer;
}
