@use 'src/shared/styles/variables/typography';

.container {
  display: flex;
  flex-direction: column;
  gap: .75rem;

  position: relative;

  overflow: hidden;

  padding: 1rem;

  border-radius: .5rem;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.category {
  @include typography.font-body-3;

  overflow: hidden;

  max-width: 160px;
  padding: 6px 12px;

  color: var(--color-black-1);
  text-overflow: ellipsis;
  white-space: nowrap;

  border-radius: 8px;
}

.time {
  @include typography.font-body-3;

  color: var(--color-secondary-3);
}

.event_name {
  @include typography.font-body-2;

  overflow: hidden;
  display: -webkit-box;

  text-overflow: ellipsis;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.background {
  position: absolute;
  inset: 0;

  opacity: .1;
  pointer-events: none;
}

