.container {
	position: relative;
}

.title {
	display: flex;
	gap: .3125rem;
}

.picker_button {
	display: flex;
	align-items: center;
	color: var(--color-primary-1);

	&:hover {
		cursor: pointer;
	}

	& svg {
		height: .5rem;
		margin-left: .75rem;
	}
}
