@use 'src/shared/styles/variables/typography';
@use 'src/shared/styles/layout/layout';

$padding-gap: layout.$card-padding;

.container {
	display: flex;
	flex: 1 0;
	padding: 0 2rem .5rem; // ?: Create a var for UserCard (listCard) padding.

	& > div {
		@include typography.font-body-2;

		flex: 1 0;
		color: var(--color-secondary-2);
		text-transform: uppercase;

		&:not(:last-child) {
			margin-right: 1rem;
		}
	}
}
