@use 'src/shared/styles/variables/typography';

.container {
  width: 100%;

  display: inline-block;
  padding: 24px;

  background-color: #FDFEFF;
  border-radius: 8px;
  box-shadow: var(--shadow-default);
}

header {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 24px;
}

.headerDisplay p {
  width: 195px;

  font-size: 22px;
  font-weight: 600;
  color: var(--color-primary-1);
  text-align: center;
}

.arrow {
  display: flex;
  cursor: pointer;

  margin: 0;
  padding: .5rem;

  fill: var(--color-secondary-2);

  &:hover {
    fill: var(--color-primary-1);
  }
}

.days,
.week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: space-between;
  margin: auto;
}

.week div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 123px;
}

.week div {
  height: 24px;
  font-size: 16px;
  color: #6C7898;

}

.eventAndBirthday {
  position: absolute;
  right: 0;
  left: 0;

  overflow-y: auto;

  max-height: 208px;
  padding: 0 8px 8px;

  text-overflow: ellipsis;
  white-space: nowrap;

  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.day {
  cursor: pointer;

  position: relative;

  overflow: hidden;
  display: block;

  min-width: 123px;
  // width: 123px;
  height: 123px;
  margin: 4px;
  padding: 16px;

  font-size: 18px;
  font-weight: 600;
  border-radius: 2px;

  background: var(--color-bg-hover-50);
  border-radius: 2px;
}

.day:hover {
  overflow: unset;
}

.day:hover .eventAndBirthday {
  z-index: 10;
  box-shadow: rgb(121 133 164 / 15%)

}

.day:hover, .day:hover .eventAndBirthday {
  color: var(--color-black-4);
  background: #EBEFF8;
}

.dayNumber {
  @include typography.font-headline-4;
  color: inherit;
  margin-bottom: 8px;
}

.weekend {
  color: var(--color-primary-1);
}

.currentDate {
  // color: #fff;
  background: var(--color-bg-active-bar);
}

.selectedDate {
  color: #fff;
  background: var(--color-primary-1);

}

.displaySelected {
  margin-bottom: 10px;
  padding: 20px;
  text-align: center;
}