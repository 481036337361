@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";

.table {
	display: flex;
	flex-direction: column;
	gap: .5rem;

	&_row {
		display: grid;
		grid-template-columns: 48px minmax(100px, 0.7fr) minmax(142px, 0.4fr) minmax(142px, 0.4fr) minmax(100px, 1fr) 52px;
		gap: 1rem;
		align-items: center;
	}

	&_header {
		@include typography.font-body-2;

		color: var(--color-secondary-3);
	}
}

.header_wrapper {
	margin: 0 1.5rem;
}

.row_wrapper {
	background-color: var(--color-bg-blocks);
	border-radius: 10px;
}

.row_card {
	@include layout.card-interactive;

	cursor: pointer;

	&_disabled {
		color: var(--color-black-3);

		& svg {
			fill: var(--color-black-3);
		}
		
		&:hover {
			cursor: default;
			box-shadow: var(--shadow-default);
		}
	}

	&_selected {
		@include layout.selected-card;

		cursor: pointer;
	}
}

.status_wrapper {
	margin: .1875rem;
}

.sub_container {
	padding: 1.5rem 1.5rem .5rem;

	& h4 {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
		padding-right: 1rem;
	}
}

.empty_block {
	padding-bottom: 1rem;
}