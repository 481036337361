@mixin gallery-item {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18.1875rem;
  height: 12.5rem;
  margin-top: 1rem;
  margin-right: 1.5rem;

  border-radius: .625rem;
  box-shadow: 0 0 .4375rem 1px rgb(5 35 181 / 8%);
}