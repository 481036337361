.title {
  margin-bottom: 24px;
  font-size: 22px;
}

.gallery {
  margin-bottom: 32px;
}

.dropzone {
  margin-bottom: 24px;
}

.image {
  width: 150px;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .formatIcon {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }

  .name {
    flex-basis: 66%;
    font-size: 14px;
  }

  .size {
    flex-basis: 33%;
  }

  & svg {
    fill: var(--color-secondary-2)
  }
}